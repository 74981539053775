import React,{useState, useEffect, useContext} from 'react';
import contentService from '../../services/api/content-service';
import {
    Container,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Row,
    Col,
    Button,
    Table,
    Spinner
} from 'reactstrap';
import moment from "moment";
import ProjectContext from '../../ProjectContext';
import ReactBSAlert from "react-bootstrap-sweetalert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faExclamationCircle, faCheckCircle} from '@fortawesome/free-solid-svg-icons'


const ArticleDetails =(props)=>{
    const articleId = props.match.params["articleId"];
    const [articleDetails, setArticleDetails] = useState({})
    const [showLoader, setShowLoader] = useState(false);
    const [showPopup, setShowPopup] = useState({alert: null})
    const projectDetails = useContext(ProjectContext);
    const [cdnUrl,setCdnUrl] = useState("https://img.cdn.sortd.mobi");

    useEffect(()=>{
        (async()=>{
            const response = await contentService.getArticleDetail({
                articleId
            });
            if(response && response.data){
                setArticleDetails(response.data)
            }

            // if(projectDetails.cloudProvider === "gcp"){
            //     setCdnUrl("https://img.cdn.sortd.mobi")
            // }
        })()
    },[])

    const resyncArticle =async()=>{
        setShowLoader(true)

        const response = await contentService.resyncArticle({
            articleId,
            projectId: projectDetails.id
        });
        setShowLoader(false)
        if(projectDetails.isContractExpired){
            setShowPopup({alert:null});
            projectDetails.setContractExpiredAlert(projectDetails);
        }
        else if(response){
            setShowPopup({
                alert: <ReactBSAlert
                            success
                            style={{ display: "block", marginTop: "-100px" }}
                            title="Completed"
                            onConfirm={() => hideAlert()}
                            onCancel={() => hideAlert()}
                            confirmBtnBsStyle="info"
                            btnSize="">
                            Article Sync Completed.
                        </ReactBSAlert>
            })
        }else{
            setShowPopup({
                alert: <ReactBSAlert
                            success
                            style={{ display: "block", marginTop: "-100px" }}
                            title="Completed"
                            onConfirm={() => hideAlert()}
                            onCancel={() => hideAlert()}
                            confirmBtnBsStyle="info"
                            btnSize="">
                            Article Sync Completed.
                        </ReactBSAlert>
            })
        }
        setTimeout(()=>{
            setShowPopup({
                alert: null
            })
        },2000)
    }


    const hideAlert =()=>{
        setShowPopup({
            alert: null
        })
    }

    return (
        <Container>
            {showPopup.alert}
            <Card>
                <CardHeader>
                    <CardTitle tag="h5">Title : {articleDetails.title}</CardTitle>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col>Article Guid:  {articleDetails.guid}</Col>
                        <Col>
                            Publisher Moment: {moment(articleDetails.article_published_on).format("DD/MM/yyyy hh:mm A")}
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col>Type: {articleDetails.type}</Col>
                        <Col>Modified On: {moment(articleDetails.article_modified_on).format("DD/MM/yyyy hh:mm A")}</Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col>Article Slug: {articleDetails.slug}</Col>
                        <Col>Sync On Sortd: {moment(articleDetails.createdAt).format("DD/MM/yyyy hh:mm A")}</Col>
                    </Row>
                    <br/>

                    <Row>
                        <Col>Article Link: <a href={articleDetails.share_url}>{articleDetails.share_url}</a></Col>
                        <Col>Last Sync On Sortd: {moment(articleDetails.updatedAt).format("DD/MM/yyyy hh:mm A")}</Col>
                    </Row>
                    <br/>

                    <Row>
                        <Col>Categories: {articleDetails.categories? articleDetails.categories.map(category=>
                                category.name
                            ).join(","):""}</Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col>
                            {
                                showLoader?
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                                :<Button className="btn btn-primary" onClick={resyncArticle}>Resync Article</Button>
                            }                            
                        </Col>
                        <Col></Col>
                       
                    </Row>

                    <Row>
                        <Col></Col>
                        <Col tag="h5">
                            Article Media
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                    <Table responsive>
                        <thead>
                            <th>#</th>
                            <th>Orignal Url</th>
                            <th>CDN Url</th>
                            <th>Status</th>
                        </thead>
                        <tbody>
                            {articleDetails.image_media ?articleDetails.image_media.map((element,index)=>
                                <tr>
                                         
                                    <td>{index+1}</td>
                                    <td>{element.url_original}</td>
                                    <td>
                                        {
                                            element.s3_keyname? 
                                            <a href={`${cdnUrl}/${element.bucket_name}/${element.s3_keyname}`}>
                                                {`${cdnUrl}/${element.bucket_name}/${element.s3_keyname}`}
                                            </a>
                                            :""
                                        }
                                    </td>
                                    <td>
                                        {
                                            element.is_processed && !element.is_failed ? <FontAwesomeIcon icon={faCheckCircle} />:  <FontAwesomeIcon icon={faExclamationCircle} />
                                        }

                                    </td>
                                </tr>
                            ):""}
                        </tbody>
                    </Table>
                    </Row>
                    


                </CardBody>
              </Card>
        </Container>
    )
}

export default ArticleDetails;